import React from "react";

const Footer = () => {
    return (
        <div id="copywright">
            <p>&copy; 2019 Karla Garrido | <span id="autor"><a href="http://rodolfoperez.info/" target="_blank" rel="noopener noreferrer">Crafted by rrorrolfo</a></span></p>
        </div>
    )
}

export default Footer;